/*-------------------------------------------
  Init Styles
-------------------------------------------*/
.init {
  animation: init_hide 0s 4.5s linear forwards;
  height: 0;
  pointer-events: none;
}
@keyframes init_hide {
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
}

.init_progress {
  appearance: none;
  background: $black;
  border: 1px solid $green;
  display: block;
  margin: rem(10) 0;
  padding: 1px;
  width: 100%;

  span {
    animation: init_progress 4s linear forwards;
    background: $green;
    display: block;
    height: 10px;
  }
}
@keyframes init_progress {
  0% {
    width: 0;
  } 25% {
    width: 10%;
  } 50% {
    width: 50%;
  } 60% {
    width: 50%;
  } 75% {
    width: 80%;
  } 90% {
    width: 80%;
  } 100% {
    width: 100%;
  }
}

.init_steps {
  @extend %list-reset;

  li {
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
    &:nth-child(2) {
      animation: init_show 0s 1.5s linear forwards;
    }
    &:nth-child(3) {
      animation: init_show 0s 2.5s linear forwards;
    }
  }
}
@keyframes init_show {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

// Show Main Content
main {
  animation: init_show 0s 5s linear forwards;
  opacity: 0;
}
