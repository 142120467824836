/*-------------------------------------------
  Global Mixins
-------------------------------------------*/
// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
	*zoom:1;
	&:before,
	&:after {
		content:"";
		display:table;
	}
	&:after {
		clear:both;
	}
}

// Media queries
@mixin max-down($max) {
    @media only screen and (max-width: $max) { @content; }
}
 
@mixin min-up($min) {
    @media only screen and (min-width: $min) { @content; }
}
 
@mixin min-max($min, $max) {
    @media only screen and (min-width: $min) and (max-width: $max) { @content; }
}

/*-------------------------------------------
  px to em
-------------------------------------------*/
$em-base: 16;

@function em($pxval, $base: $em-base) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1em;
}

/*-------------------------------------------
  px to rem
-------------------------------------------*/

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}

	$base: $em-base;
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1rem;
}

/*-------------------------------------------
  Grid
-------------------------------------------*/
@mixin grid($total-width, $gutter-width, $row-count, $element) {
	#{$element} {
		$g: $gutter-width/$total-width;
		float: left;
		margin-bottom: $g * 100%;
		width: ((1 - ($g * ($row-count - 1))) / $row-count) * 100%;
		
		&:nth-child(#{$row-count}n) {
			margin-right: 0;
		}
	}
	#{$element}:not(:nth-child(#{$row-count}n)) {
		$g: $gutter-width/$total-width;
		margin-right: $g * 100%;
	}
}

/* USAGE:

ul {
	background: darkblue;
	list-style: none;
	margin: 0 auto;
	max-width: 960px;
	overflow: hidden;
	
	@include grid(960, 20, 3, li);
	
	li {
		background: darkred;
		height: 100px;
		text-indent: -999em;
		
		&:nth-child(2n+2) {
			-webkit-animation: silly2 5s ease-in-out 0s infinite alternate;			
		}
		&:nth-child(3n) {
			-webkit-animation: silly 5s ease-in-out 0s infinite alternate;
		}
	}
	
	@media screen and (min-width: 30em) {
		@include grid(960, 20, 4, li);
	}
	@media screen and (min-width: 40em) {
		@include grid(960, 20, 6, li);
	}
	
}

*/
