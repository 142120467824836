/*-------------------------------------------
  Entry Styles
-------------------------------------------*/
.entry {
  p, ol, ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
  ol {
    list-style: decimal;
  }
  img {
    display: block;
    height: auto;
    margin-bottom: 1.5em;
    max-width: 100%;
  }
}
.entry_stamp {
  font-weight: 700;

  @include min-up($tablet) {
    margin-left: -1.5em;
  }
}
.entry_img {
  display: inline-block;
  position: relative;
}
