/*-------------------------------------------
  General
-------------------------------------------*/
html {
  height: 100%;
}
body {
  background: $black;
  @extend %antialias;
  color: $green;
  font-family: $body-font;
  min-height: 100%;
  padding: rem(30) $body-pad;
  position: relative;
  text-shadow: 0 0 3px $green;

  &:after {
    background-image: linear-gradient(transparent, transparent 66.7%, transparentize($black, .75) 66.7%),
                      linear-gradient(90deg, transparent, transparent 66.7%, transparentize($black, .75) 66.7%);
    background-size: 2px 2px;
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }

  &.inverse {
    background: $white;
    color: $black;
    text-shadow: none;

    &:after {
      background-image: linear-gradient(transparent, transparent 66.7%, transparentize($white, .75) 66.7%),
                        linear-gradient(90deg, transparent, transparent 66.7%, transparentize($white, .75) 66.7%);
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
  	only screen and (-o-min-device-pixel-ratio: 13/10),
  	only screen and (min-resolution: 120dpi) {
      &:after {
        background-image: linear-gradient(transparent, transparent 66.7%, transparentize($black, .35) 66.7%),
                          linear-gradient(90deg, transparent, transparent 66.7%, transparentize($black, .35) 66.7%);
        background-size: 1px 1px;
      }

      &.inverse {
        &:after {
          background-image: linear-gradient(transparent, transparent 66.7%, transparentize($white, .5) 66.7%),
                            linear-gradient(90deg, transparent, transparent 66.7%, transparentize($white, .5) 66.7%);
        }
      }
  }

  @include min-up($tablet) {
    padding: rem(40) $body-pad-lg;
  }
}

// Disable pixel grid on Firefox (caused performance issues)
@-moz-document url-prefix() {
  body {
    &:after {
      display: none;
    }
  }
}

// Wrapper
.wrap {
  max-width: $wrap-max-width;
}

// Text Selection
::selection {
	background: transparentize($green, .01);
	color: $black;
  text-shadow: 0 0 3px transparentize($black, .5);
}
::-moz-selection {
	background: transparentize($green, .01);
	color: $black;
  text-shadow: 0 0 3px transparentize($black, .5);
}

/*-------------------------------------------
  Animations
-------------------------------------------*/
%transition {
  transition: all .25s linear;
}

/*-------------------------------------------
  Lists
-------------------------------------------*/
%list-reset {
	list-style: none;
	margin: 0;
}
%inline-list {
	@extend %list-reset;

	> li {
		display: inline-block;
	}
}

/*-------------------------------------------
  Links
-------------------------------------------*/
a {
	// border-bottom: 1px solid $accent1;
	color: $accent2;
	text-decoration: underline;
	@extend %transition;

	&:hover {
		color: $accent1;
	}
}
